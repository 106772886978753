import axios from "axios";
import { useCallback, useState } from "react";

import { useAuth } from "./useAuth";

const API_URL_PREFIX = process.env.API_URL;

const useApi = (logoutOnError = true) => {
  const { userInfo, logout } = useAuth();
  const { token } = userInfo;

  const [response, setResponse] = useState(false);
  const [status, setStatus] = useState("uninitialized");

  const clear = () => {
    setResponse(false);
    setStatus("uninitialized");
  };

  const sendRequest = ({
    endpoint,
    method,
    data,
    addPrefix = true,
    addContentType,
    abortController,
    addContentTypeMedia,
    addToken = true,
  }) => {
    setStatus("wait");

    let headers = {
      "Content-Type": addContentType ? "application/json" :
        addContentTypeMedia ? "multipart/form-data" : "",
      "x-application": `office.${process.env.REACT_APP_IS_PRODUCTION === "true" ? "prod" : "qa"}`
    };

    if (addToken) {
      headers.Authorization = `Bearer ${token}`;
    }

    return new Promise((resolve, reject) => {
      axios({
        method: method,
        url: addPrefix ? `${API_URL_PREFIX}/${endpoint}` : endpoint,
        headers: headers,
        data: data,
        ...(abortController && { signal: abortController.signal }),
      })
        .then((response) => {
          setStatus("complete");
          setResponse(response);

          resolve(response);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            if (logoutOnError && [401].indexOf(err?.response?.status) !== -1) {
              // logout();
              console.log('A 401 error has occurred.');
              console.log('Endpoint:', `${API_URL_PREFIX}/${endpoint}`);
              console.log('headers:', headers)
              console.log('data:', data)
              setStatus("error");
              setResponse(err);

              reject(err);
            } else {
              setStatus("error");
              setResponse(err);

              reject(err);
            }
          }
        });
    });
  };

  const sendRequestAsCallback = useCallback(async ({
    endpoint,
    method,
    data,
    addPrefix = true,
    addContentType,
    abortController,
    addContentTypeMedia,
    acceptLanguage,
    addToken = true,
  }) => {
    setStatus("wait");

    const headers = {
      Authorization: addToken ? `Bearer ${token}` : "",
      "Content-Type": addContentType ? "application/json" :
        addContentTypeMedia ? "multipart/form-data" : "",
      "Accept-Language": acceptLanguage ? acceptLanguage : "",
      "x-application": `office.${process.env.REACT_APP_IS_PRODUCTION === "true" ? "prod" : "qa"}`
    }

    return new Promise((resolve, reject) => {
      axios({
        method: method,
        url: addPrefix ? `${API_URL_PREFIX}/${endpoint}` : endpoint,
        headers: headers,
        data: data,
        ...(abortController && { signal: abortController.signal }),
      })
        .then((response) => {
          setStatus("complete");
          setResponse(response);

          resolve(response);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            if (logoutOnError && [401].indexOf(err?.response?.status) !== -1) {
              console.log('A 401 error has occurred.');
              console.log('Endpoint:', `${API_URL_PREFIX}/${endpoint}`);
              console.log('headers:', headers)
              console.log('data:', data)
              // logout();
              setStatus("error");
              setResponse(err);

              reject(err);
            } else {
              setStatus("error");
              setResponse(err);

              reject(err);
            }
          }
        });
    });
    // eslint-disable-next-line
  }, [logout, token]);

  return { response, status, sendRequest, clear, sendRequestAsCallback };
};

export { useApi };
