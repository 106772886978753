/**
 * Util helper to set cookies
 * @param {*} name 
 * @param {*} value 
 * @param {*} days 
 */
const setCookie = (name, domain, value) => {
  document.cookie = `${name}=${value}; path=/; domain=${domain}`;
}

export { setCookie }