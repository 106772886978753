import React, { useContext } from "react"

import { DataGridPro } from '@mui/x-data-grid-pro';
import { Box, Typography, CircularProgress } from '@mui/material';

import { CenturionContext } from "../../hooks/CenturionContext";
import { ClientSidePagination } from "../../components/ClientSidePagination.jsx";
import { CenturionNoSalesOverlay } from "./CenturionNoSalesOverlay";

import { mobileColumns } from "./format-centurion-columns.js"
import "./CenturionDataGridMobile.css"

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const CenturionDataGridMobile = () => {
  const { currentMembers, status } = useContext(CenturionContext);

  const theme = useTheme();
  const viewPortIsSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box id="CenturionDataGrid">
      <Box id="CenturionDataGridMobileWrapper">
        <Box sx={{ padding: "24px 24px 0px 24px" }}>
          <Typography id="sales-header" variant="h3">Centurion Sales</Typography>
        </Box>
        {
          status !== "complete" &&
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "300px" }}>
            <CircularProgress data-test-id="progress-bar-shown" />
          </Box>
        }
        {
          status === "complete" && currentMembers.length === 0 &&
          <CenturionNoSalesOverlay />
        }
        {
          status === "complete" && currentMembers.length !== 0 &&
          <DataGridPro
            rows={currentMembers}
            disableColumnMenu
            columns={mobileColumns}
            getRowHeight={() => {
              return 72;
            }}
            pagination
            rowsPerPageOptions={[10]}
            disableRowSelectionOnClick
            initialState={{
              sorting: {
                sortModel: [{ field: 'count', sort: 'desc' }],
              },
              pagination: { paginationModel: { pageSize: viewPortIsSmall ? 25 : 10 } },
            }}
            components={{
              Pagination: ClientSidePagination,
            }}
          />
        }
      </Box>
    </Box>

  );
}

export { CenturionDataGridMobile }