import React, { useContext } from "react"

import { Box, Typography, CircularProgress } from "@mui/material";
// Radial separators
import RadialSeparators from "./RadialSeparators";

import { CenturionContext } from "../hooks/CenturionContext";
import { formatTextSizeWeight } from "../screens/CenturionScreen/format-functions"

// https://www.npmjs.com/package/react-circular-progressbar
import {
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { LanguageContext } from "../hooks/LanguageContext";


const CenturionProgressCircle = ({ isDashboard }) => {
  const { sales, status, isError } = useContext(CenturionContext);
  const { localizeNumber } = useContext(LanguageContext)

  if (status === "complete" || isError) return (
    <CircularProgressbarWithChildren
      data-test-id="centurion-progress-circle"
      value={sales}
      strokeWidth={5}
      styles={buildStyles({
        pathColor: "rgba(255, 173, 145, 1)",
        trailColor: "rgba(255, 236, 229, 1)",
        strokeLinecap: "round"
      })}
    >
      <Typography sx={formatTextSizeWeight(isDashboard ? "40px" : "60px", 700)}>{localizeNumber(sales)}</Typography>
      <Typography sx={{ fontWeight: "500", fontSize: "17.5px", opacity: ".6" }}>{`${isError ? "/0" : "/100"}`}</Typography>
      <RadialSeparators
        data-test-id="centurion-progress-circle-separators"
        count={3}
        style={{
          background: "#fff",
          width: "2px",
          // This needs to be equal to props.strokeWidth
          height: `${5}%`
        }}
      />
    </CircularProgressbarWithChildren>
  )

  return (
    <Box sx={{
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
      marginTop: isDashboard ? "35px" : ""
    }}>
      <CircularProgress data-test-id="progress-bar-shown" />
    </Box>
  )
}

export { CenturionProgressCircle }