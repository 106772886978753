import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { T } from "../../hooks/useTranslate";

import { Alert, Box, CircularProgress, Divider } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from "@mui/icons-material/Close";

import { AvatarChip } from "./AvatarChip";
import { OrderNumberChip } from "./OrderNumberChip";
import { OrderTable } from "./OrderTable";
import { MemberContext } from "../../hooks/MemberContext";
import { LanguageContext } from "../../hooks/LanguageContext";
import { LoadMoreOrdersButton } from "./LoadMoreOrdersButton";


const OrderSummaryDrawerContent = ({ isWebView }) => {
  const { apiChainInProgress,
    customerOrders,
    customerHrefData,
    memberId,
    allOrdersShown,
    customerOrdersOffset,
    setCustomerOrdersOffset,
    customerOrdersOffsetLoading,
    customerOrdersInitialLength,
    setIsDrawerOpen
  } = useContext(MemberContext);
  const { languageDir } = useContext(LanguageContext);

  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const drawer = searchParams.get("drawer");

  const handleArrowClick = () => {
    navigate(-1, { replace: true });
  }

  const handleCloseClick = () => {
    setIsDrawerOpen(false);
    navigate(`${location.pathname}`, { replace: true });
  }

  const handleChipClick = () => {
    navigate(`${location.pathname}?drawer=${drawer}&id=${memberId}&drawerContent=profile`, { state: location });
  }

  // render loading bar while API chain in in progress
  if (apiChainInProgress)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 500,
        }}
      >
        <CircularProgress data-test-id="progress-bar-shown" />
      </Box>
    );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "24px 0px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: isWebView ? "flex-end" : "space-between",
          padding: "0px 24px",
        }}
      >
        {!isWebView && (languageDir === "ltr" ?
          <KeyboardBackspaceIcon
            onClick={handleArrowClick}
            sx={{ cursor: "pointer" }}
          /> :
          <ArrowForwardIcon
            onClick={handleArrowClick}
            sx={{ cursor: "pointer" }}
          />)}
        <CloseIcon onClick={handleCloseClick} sx={{ cursor: "pointer" }} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: isWebView ? "flex-end" : "space-between",
          padding: "8px 24px",
        }}
      >
        <AvatarChip
          id="orderAvatarChip"
          imageUrl={customerHrefData?.profilePicture?.href}
          name={`${customerHrefData?.humanName?.firstName} ${customerHrefData?.humanName?.lastName}`}
          clickHandler={handleChipClick}
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", padding: "0px 24px", gap: "10px" }}>
        <span
          style={{ fontSize: "16px", fontWeight: "600" }}
          data-test-id="orders-summary-header"
        >
          <T>orders</T>
        </span>
        <OrderNumberChip id="orders-summary-chip">{customerOrdersInitialLength < 11 ? customerOrdersInitialLength : "10+"}</OrderNumberChip>
      </Box>
      {customerOrders === 0 && (
        <Alert id="ordersError" severity="info">
          <T>no_orders_text</T>
        </Alert>
      )}
      {!apiChainInProgress && customerOrders.length !== 0 && (
        <div style={{ display: "flex", flexDirection: "column", height: "100%", padding: "0px 24px" }}>
          <div id="OrdersDataGrid" style={{ flexGrow: 1 }}>
            <OrderTable orders={customerOrders || []} />
          </div>
          <Divider sx={{ borderColor: "#EDEFF1" }} />
          {
            !allOrdersShown && (
              <LoadMoreOrdersButton
                customerOrdersOffset={customerOrdersOffset}
                setCustomerOrdersOffset={setCustomerOrdersOffset}
                customerOrdersOffsetLoading={customerOrdersOffsetLoading}
              />
            )
          }
        </div>
      )}
    </Box>
  );
};

export { OrderSummaryDrawerContent };
