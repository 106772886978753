import React from "react";
import { T, useTranslateKey } from "../hooks/useTranslate";
import { useAuth } from "../hooks/useAuth";
import { Link } from "react-router-dom";
import { Alert } from "@mui/material";

const LoginErrorAlert = () => {
  const { userInfo, errorCode } = useAuth();
  const translatedUrl = useTranslateKey("member_help_center_url");

  if (errorCode === 429) {
    return (
      <Alert id="loginError" severity="warning">
        <T>too_many_requests</T>
      </Alert>
    )
  }

  return (
    <>
      {["error", "unauthorized"].includes(userInfo.state) && (
        <Alert id="loginError" severity="error">
          {userInfo.state === "error" && (
            <T>login_or_password_is_incorrect</T>
          )}
          {userInfo.state === "unauthorized" && (
            <span>
              <T>unauthorized_login</T>&nbsp;
              <Link id="memberHelpCenterLink" href={translatedUrl}>
                <T>member_help_center</T>
              </Link>
            </span>
          )}
        </Alert>
      )}
    </>
  )
}

export { LoginErrorAlert }