import React, { useContext } from "react";
import { T } from "../../hooks/useTranslate";

import { DataGridPro } from "@mui/x-data-grid-pro";
import { Box, Typography, CircularProgress } from "@mui/material";

import { CenturionContext } from "../../hooks/CenturionContext";
import { ClientSidePagination } from "../../components/ClientSidePagination.jsx";
import { CenturionNoSalesOverlay } from "./CenturionNoSalesOverlay";

import { columns } from "./format-centurion-columns.js";
import "./CenturionDataGrid.css";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const CenturionDataGrid = () => {
  const { currentMembers, status } = useContext(CenturionContext);

  const theme = useTheme();
  const viewportIsLarge = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Box id="CenturionDataGrid">
      {/* height comes from the is needed to fill the centurion sales container based on the adjacent child */}
      <Box id="CenturionDataGridWrapper" sx={{ height: viewportIsLarge ? "633.781px" : "769.328px" }}>
        <Box sx={{ padding: "24px 24px 0px 24px" }}>
          <Typography data-test-id="centurion-qual-header2" variant="h3">
            <T>centurion_sales</T>
          </Typography>
        </Box>
        {status !== "complete" && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "50vh",
            }}
          >
            <CircularProgress data-test-id="progress-bar-shown" />
          </Box>
        )}
        {status === "complete" && currentMembers.length === 0 && (
          <CenturionNoSalesOverlay />
        )}
        {status === "complete" && currentMembers.length !== 0 && (
          <DataGridPro
            rows={currentMembers}
            disableColumnMenu
            columns={columns}
            pagination
            sortingOrder={["desc", "asc"]}
            pageSizeOptions={[10, 25, 50]}
            disableRowSelectionOnClick
            hideFooterSelectedRowCount
            slots={{
              noRowsOverlay: CenturionNoSalesOverlay,
              noResultsOverlay: CenturionNoSalesOverlay,
              pagination: ClientSidePagination
            }}
            slotProps={{
              pagination: {
                perPageKey: "sales_per_page"
              }
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'count', sort: 'desc' }],
              },
              pagination: { paginationModel: { pageSize: !viewportIsLarge ? 25 : 10 } },
            }}
          />
        )}
      </Box>
    </Box>

  );
};

export { CenturionDataGrid };
