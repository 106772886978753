import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";


import { OfficePage } from "../../components/OfficePage";
import { MemberContextProvider } from "../../hooks/MemberContext";
import { RecentActivityContent } from "./RecentActivity/RecentActivityContent";
import { RankQualificationsContent } from "./RankQualifications/RankQualificationsContent";
import { SsnEntryActionItem } from "./SsnEntryActionItem/SsnEntryActionItem";
import { CenturionQualificationsContent } from "./CenturionQualifications/CenturionQualificationsContent";
import { StatsContent } from "./Stats/StatsContent";
import { ExplainedButton } from "../../components/ExplainedButton";
import { NewsContent } from "./News/NewsContent";
import { GlobalNoticeComponent } from "../../components/GlobalNoticeComponent";
import { setCookie } from "../../common/cookie";
import FeelGreatLink from "../../components/FeelGreatLink";
import { useAuth } from "../../hooks/useAuth";
import { T } from "../../hooks/useTranslate";
import { OfficeBox } from "../../components/OfficeBox";
import { useApi } from "../../hooks/useApi";

const DashboardScreen = () => {
  const theme = useTheme();
  const viewportIsLarge = useMediaQuery(theme.breakpoints.up("lg"));
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));

  const navigate = useNavigate();
  const [link, setLink] = useState("Loading...");
  const [referralCode, setReferralCode] = useState("");
  const [showCode, setShowCode] = useState(false);

  const { response, status, sendRequest } = useApi();

  const { userInfo } = useAuth();

  const handleToggle = () => {
    setShowCode(!showCode);
  };

  const toggleText = showCode ? <T>show_link</T> : <T>show_code</T>


  useEffect(() => {
    sendRequest({
      method: "get",
      endpoint: `customers/me/referralcodes?__resetCaches=1`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (status === "complete") {
      setReferralCode(response.data.items[0].referralCode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  useEffect(() => {
    if (referralCode) {
      setLink(`${process.env.REACT_APP_FEEL_GREAT_REFERRAL_URL}/${referralCode}`);
    } else {
      setLink(`${process.env.REACT_APP_FEEL_GREAT_REFERRAL_URL}/${userInfo.unicityId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralCode]);

  useEffect(() => {
    // Redirect to /dashboard if accessed via root URL "/"
    const currentPath = window.location.pathname;
    if (currentPath !== "/dashboard") {
      navigate("/dashboard", { replace: true });
    }
  }, [navigate]);

  useEffect(() => {
    if (!userInfo) return
    const isInProd = process.env.REACT_APP_IS_PRODUCTION === "true"
    const cookieName = isInProd ? "_unicityToken_v5" : "_unicityToken_v5Test";
    const cookieValue = userInfo.token;
    const domain = isInProd ? "unicity.com" : "unicityqa.com";
    setCookie(cookieName, domain, cookieValue)
  }, [userInfo])

  return (
    <OfficePage pageTitle="dashboard">
      <MemberContextProvider>
        <Stack
          direction={viewportIsLarge ? "row" : "column"}
          gap={viewportIsLarge ? "18px" : "20px"}
          alignItems={viewportIsLarge ? "flex-start" : "stretch"}>
          <Stack sx={{ flex: "1 1 100%" }} gap="20px">
            {/* show global notice component at this level if not large viewport */}
            {!viewportIsLarge && <GlobalNoticeComponent />}
            <SsnEntryActionItem />
            <RankQualificationsContent />
            {
              viewportIsLarge &&
              <StatsContent />
            }
          </Stack>
          <Stack sx={{ flex: "1 1 100%" }} gap="20px">
            {/* back to old office button removed - ref: https://app.clickup.com/t/86dtx5zdj */}
            <CenturionQualificationsContent />
          </Stack>
          {
            !viewportIsLarge &&
            <Stack sx={{ flex: "1 1 100%" }}>
              <StatsContent />
            </Stack>
          }
          <Stack gap="20px" sx={{ flex: "1 1 100%" }}>
            <NewsContent />
            <RecentActivityContent />
            <OfficeBox sx={{ paddingTop: '20px' }}>
              <FeelGreatLink
                viewportIsSmall={viewportIsSmall}
                handleToggle={handleToggle}
                toggleText={toggleText}
                link={link}
                showCode={showCode}
                referralCode="test12345" 
                isDashboard
              />
            </OfficeBox>
          </Stack>
        </Stack>
        <Stack alignItems="center" mt={viewportIsLarge ? 10 : 4} mb={viewportIsLarge ? 10 : 4}>
          <ExplainedButton
            type="dashboard"
            testId="dashboard-explained-button"
            imgUrl="icons/centurion-info.svg"
            buttonText="dashboard_explained"
            urlKey="dashboard_explained_url"
          />
        </Stack>
      </MemberContextProvider>
    </OfficePage>
  );
};

export { DashboardScreen };
