import { useContext } from "react";
import { Box, Link, Stack } from "@mui/material";
import { PartnersContext } from "../../../hooks/PartnersContext";

import { useNavigate, useLocation } from "react-router-dom";

import { useMixPanel } from '../../../hooks/useMixPanel';

import { OfficeAvatar } from "../../../components/OfficeAvatar";
import { PartnerNameContent } from "./PartnerNameContent";
import { ChipContainer, BottomChips, TopChips } from "./ChipContainer";

import { parseProfilePictureUrl, isDate60DaysInactive } from "../../../common/helper-functions"

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";


const PartnersNameCell = ({ params: member, customerType, dataGridParams }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logEvent } = useMixPanel();
  const theme = useTheme();
  const viewportIsLarge = useMediaQuery(theme.breakpoints.up("lg"));
  const { leg1UnicityId, leg2UnicityId } = useContext(PartnersContext);
  const isSearchResultsPage = location.pathname.includes("search-results");
  const profilePictureUrl = parseProfilePictureUrl(member.profilePicture);
  const cellWidth = dataGridParams.colDef.computedWidth;
  // mirror logic in the ChipContainer to determine the gap for the name cell
  const nameCellHasChip = 
    customerType === "member" || 
    member.customerStatus === "Suspended" || 
    (customerType === "partner" && !member.is100PvActive) || 
    (customerType === "member" && isDate60DaysInactive(member.lastOrderDate)) ||
    member?.unicityId?.trim() === leg1UnicityId?.trim() ||
    member?.unicityId?.trim() === leg2UnicityId?.trim()
  const calculatedGap = nameCellHasChip ? (viewportIsLarge ? "" : "2px") : "2px";
  
  const showBottomChipSeparatorDot = customerType === "member" ||
  member.customerStatus === "Suspended" || 
  (customerType === "partner" && !member.is100PvActive) || 
  (customerType === "member" && isDate60DaysInactive(member.lastOrderDate))
  
  const handleNameClick = () => {
    logEvent("open_profile", {
      location: isSearchResultsPage ? "partners_search_results" : "partners_page",
      user_type: "partner"
    })
    navigate(`${location.pathname}${location.search ? location.search : "?"}&drawer=partner&id=${member.unicityId}&drawerContent=profile`, {
      state: {
        location: location,
        userFirstName: member.firstName,
        userFullName: member.name,
        totalPartners: member.totalPartners,
      },
    });
  };

  return (
    <Box
      className="flex-row"
      data-test-id="name-cell-container"
      data-field="name-cell-container"
      gap={1}
      sx={{ opacity: member.customerStatus === "Suspended" && ".6" }}
    >
      <Box>
        <OfficeAvatar
          imageUrl={profilePictureUrl}
          firstInitial={member.name[0]?.toUpperCase()}
          id="first-letter"
        />
      </Box>
      <Box className="flex-column" style={{ alignItems: "baseline", gap: calculatedGap }}>
        <Stack direction="row" alignItems="center" gap={.5}>
          {
            customerType === "member" ? (
              <PartnerNameContent member={member} cellWidth={cellWidth} />
            ) : (
              <Stack direction="row" spacing={1}>
                <Link
                  sx={{ fontSize: "12px" }}
                  component="button"
                  underline="hover"
                  href="#"
                  onClick={handleNameClick}
                >
                  <PartnerNameContent member={member} cellWidth={cellWidth} />
                </Link>
              </Stack>
            )
          }
          {viewportIsLarge && nameCellHasChip && (
            <ChipContainer
              viewportIsLarge={viewportIsLarge}
              customerType={customerType}
              member={member}
              leg1UnicityId={leg1UnicityId}
              leg2UnicityId={leg2UnicityId}
            />
          )}
          {!viewportIsLarge && nameCellHasChip && (
            <TopChips
              leg1UnicityId={leg1UnicityId}
              leg2UnicityId={leg2UnicityId}
              member={member} 
            />
          )}
        </Stack>
        <Stack
          direction={!viewportIsLarge ? "row" : "column"}
          data-field="unicity-id"
          data-test-id="unicity-id"
        >
          <span style={{
            fontSize: "10px",
            fontWeight: 400,
            opacity: "60%",
            alignItems: "flex-start"
          }}>
            {member.unicityId}
            {!viewportIsLarge && showBottomChipSeparatorDot && <span style={{ marginLeft: '2px' }}>{"•"}</span>}
          </span>
          {
            !viewportIsLarge && nameCellHasChip && (
              <Stack direction="row" gap={.5}>
                <BottomChips member={member} customerType={customerType} />
              </Stack>
            )
          }
        </Stack>
      </Box >
    </Box >
  );
};

export { PartnersNameCell };
