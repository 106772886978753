import React, { useContext } from "react"

import { useNavigate, useLocation } from "react-router-dom"

import { Link } from "@mui/material"
import { LanguageContext } from "../hooks/LanguageContext";

const OrdersCell = ({ params: member }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { localizeNumber } = useContext(LanguageContext)

  const localizedOrders = localizeNumber(member.orders);

  const handleClick = () => {
    navigate(`${location.pathname}?drawer=member&id=${member.unicityId}&drawerContent=orders`, { state: location });
  }

  if (member.orders === 0) return <div>{member.orders}</div>

  return (
    <Link
      onClick={handleClick}
      sx={{
        cursor: "pointer",
        fontSize: "12px"
      }}
    >
      {localizedOrders}
    </Link>
  )
}

export { OrdersCell }