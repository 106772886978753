// handleDownloadClick is an event handler for the click event of the download link.
// It prevents the default action of the event, constructs the download URL with the necessary authorization token,
// fetches the file as a blob from the URL, creates a URL representing the blob,
// and opens this URL in a new browser tab to view the downloaded PDF.


export const handleDownloadClick = (statementHref, token, setLoading) => (event) => {
  setLoading(true);
  event.preventDefault();
  const url = `${statementHref}?_httpHeaderAuthorization=Bearer%20${token}`;
  fetch(url, {
    headers: {
      "x-application": `office.${process.env.REACT_APP_IS_PRODUCTION === "true" ? "prod" : "qa"}`
    }
  })
    .then(response => response.blob())
    .then(blob => {
      const blobUrl = window.URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    })
    .catch(err => {
      console.log(err);
    })
    .finally(() => {
      setLoading(false);
    });
};