import { T } from "../../hooks/useTranslate";

import Flag from "react-world-flags";
import { Box, Stack } from "@mui/material";

import { OrdersCell } from "../../components/OrdersCell";
import { ReferralsCell } from "../../components/ReferralsCell";
import { MemberInfo } from "./MemberInfo";
import { Chip } from "./Chip"

import { decodeString } from "../../common/format-functions";
import { FormatDate } from "../../components/FormatDate";
import { LastOrderPvCell } from "../../components/LastOrderPvCell";

const getCountryState = (params) =>
  params.row.state
    ? `${params.row.country}, ${params.row.state}`
    : params.row.country;

const getHeaderNameFromFieldName = (fieldName) =>
  columns.find((c) => c.field === fieldName).headerName;

const headerTextStyle = { fontWeight: "600" }

const columns = [
  {
    headerName: "name_uid",
    flex: 1.25,
    minWidth: 180,
    field: "name",
    renderCell: (params) => (
      <MemberInfo
        params={params.row}
        dataGridParams={params}
      />
    ),
    renderHeader: () => <Box sx={headerTextStyle} data-test-id={`name-uid-column-header`}><T>name_uid</T></Box>
  },
  {
    headerName: "date_joined",
    field: "joinDate",
    flex: 0.75,
    minWidth: 100,
    renderCell: (params) => <FormatDate dateString={params.row.joinDate} />,
    renderHeader: () => <Box sx={headerTextStyle} data-test-id={`date-joined-column-header`}><T>date_joined</T></Box>
  },
  {
    headerName: "orders",
    field: "orders",
    align: "right",
    headerAlign: "right",
    flex: 0.6,
    minWidth: 90,
    renderCell: (params) => <OrdersCell params={params.row} />,
    renderHeader: () => <Box sx={headerTextStyle} data-test-id={`orders-column-header`}><T>orders</T></Box>
  },
  {
    headerName: "referrals",
    field: "referrals",
    align: "right",
    headerAlign: "right",
    flex: 0.6,
    minWidth: 98,
    renderCell: (params) => <ReferralsCell params={params.row} />,
    renderHeader: () => <Box sx={headerTextStyle} data-test-id={`referrals-column-header`}><T>referrals</T></Box>
  },
  {
    headerName: "last_order_date",
    field: "lastOrderDate",
    align: "right",
    headerAlign: "right",
    flex: 1,
    minWidth: 122,
    renderCell: (params) => <FormatDate dateString={params.row.lastOrderDate} />,
    renderHeader: () => <Box sx={headerTextStyle} data-test-id={`last-order-date-column-header`}><T>last_order_date</T></Box>
  },
  {
    headerName: "last_order_pv",
    field: "lastOrderPv",
    align: "right",
    headerAlign: "right",
    flex: 0.7,
    minWidth: 123,
    renderCell: (params) => <LastOrderPvCell params={params.row} />,
    renderHeader: () => <Box sx={headerTextStyle} data-test-id={`last-order-pv-column-header`}><T>last_order_pv</T></Box>
  },
  {
    headerName: "subscription",
    field: "subscriptionRenewalDate",
    flex: 0.8,
    minWidth: 118,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => <Chip subscriptionRenewalDate={params.row.subscriptionRenewalDate} />,
    renderHeader: () => <Box sx={headerTextStyle} data-test-id={`subscription-column-header`}><T>subscription</T></Box>
  },
  {
    headerName: "country_state",
    field: "country",
    flex: 0.8,
    minWidth: 180,
    renderCell: (params) => {
      return (
        <Stack className="flex-row" direction="row" gap={1}>
          <Flag height="20" width="29" code={params.row.country} />
          <div>{decodeString(getCountryState(params))}</div>
        </Stack>
      );
    },
    renderHeader: () => <Box sx={headerTextStyle} data-test-id={`country-state-column-header`}><T>country_state</T></Box>
  },
  {
    headerName: "relevance",
    field: "relevance",
    hideFromGrid: true,
    disableWhenNoSearch: true
  },
];

const formatFieldNameTable = {
  asc: "Ascending",
  desc: "Descending",
  name: "Name/UID",
  joinDate: "Date joined",
  orderCount: "Orders",
  referralCount: "Referrals",
  lastOrderDate: "Last order date",
  lastOrderPv: "Last order PV",
  subscriptionRenewalDate: "Subscription",
  country: "Country/state",
};

export { columns, formatFieldNameTable, getHeaderNameFromFieldName };
