import { dateToString } from "../../common/date-functions";

const getDescription = (order) => {
  let toReturn = "";

  const items = order.lines?.items;

  if (items && items.length > 0) {
    toReturn = items[0].catalogSlide?.content?.description;

    if (items.length > 1) {
      toReturn = toReturn + `, + ${items.length - 1} more`;
    }
  } else if (order.paymentStatus && order.fulfillmentStatus) {
    toReturn = `${order.paymentStatus} ${order.fulfillmentStatus} Order`;
  } else {
    toReturn = `Order Total: $${order.terms?.total}`
  }

  return toReturn;
};



const formatDescription = (description, maxLength) => {
  if (description.length > maxLength)
    return description.slice(0, maxLength) + "...";

  return description;
};

const OrderSummaryCell = ({ order }) => {
  return (
    <div
      title={getDescription(order)}
      aria-label={getDescription(order)}
      style={{ display: "grid", gridTemplateColumns: "25px auto" }}
    >
      <img
        alt={`${order.type === "Order" ? "One-time order" : "Autorefill order"}`}
        src={
          order.type === "Order"
            ? `icons/order-icon-onetime.svg`
            : `icons/order-icon-autoship-v2.svg`
        }
        style={{ width: "20px", height: "20px", marginTop: "4px" }}
      />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ fontWeight: 600 }} data-test-id={`order-title-${order.id.unicity}`}>{formatDescription(getDescription(order), 23)}</div>
        <div style={{ fontSize: "10px", opacity: ".6" }}>
          {
            order.dateCreated ?
              <div>
                <span data-test-id={`order-date-${order.id.unicity}`}>{`${dateToString(new Date(order.dateCreated))}`}</span>{` • `}
                <span data-test-id={`order-id-${order.id.unicity}`}>{`${order.id.unicity}`}</span>
              </div>
              :
              <span data-test-id={`order-id-${order.id.unicity}`}>{`${order.id.unicity}`}</span>

          }
        </div>
      </div>
    </div>
  );
};

export { OrderSummaryCell };
