import React, { useRef, useEffect, useContext } from "react";
import { T, useTranslateKey } from "../../hooks/useTranslate";
import { useMixPanel } from "../../hooks/useMixPanel";
import {
  Box,
  Stack,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Link,
  Badge
} from "@mui/material";
import { useAuth } from "../../hooks/useAuth.js";
import { LanguageContext } from "../../hooks/LanguageContext";
import { ActionableIndicator } from "../../components/ActionableIndicator";
import { useActionableItemsContext } from "../../hooks/ActionableItemsContext";
import { useTheme } from "@mui/material/styles";

const ResourceNavItem = ({
  open,
  item,
  drawerOpen,
  isMobile,
  setDrawerWidth,
  shopLink
}) => {
  const elementRef = useRef(null);
  const { userInfo } = useAuth();
  const translatedItemTitle = useTranslateKey(item.text);
  const { logEvent } = useMixPanel();
  const { languageDir } = useContext(LanguageContext);
  const { isActionable } = useActionableItemsContext();
  const theme = useTheme()

  const handleNavClick = () => {
    logEvent(item.eventName, { location: "menu" });
  }

  // widen the drawer if the text box breaks the 102 threshold
  useEffect(() => {
    if (elementRef.current) {
      const width = elementRef.current.getBoundingClientRect().width;
      if (width > 105) {
        setDrawerWidth(width + 150)
      }
    }
    // eslint-disable-next-line
  }, []);

  const distributorHelpCenterUrl = useTranslateKey("distributor_help_center_url");

  const newLibraryLinks = {
    US: "https://ufind.unicity.com/web/446a0e1efd327b5e/usa/",
    CA: "https://ufind.unicity.com/web/3af7f61bfdbafc2d/canada/"
  }

  // if building the library link, add the base64 converted token to the url
  const librarySilentLoginLink = item.url.includes("library")
    ? `${item.url}/${btoa(userInfo.token)}`
    : item.url;

  const shopFeelGreatLink = `${process.env.REACT_APP_FEEL_GREAT_URL}`;

  const resourceHref = item.value === "new-library" ? newLibraryLinks[userInfo.countryCode] :
    item.value === "external-help" ? distributorHelpCenterUrl :
      item.value === "shop-feel-great" ? shopFeelGreatLink :
        item.value === "shop-unicity" ? shopLink :
          item.value === "library" ? librarySilentLoginLink : "";

  const commonProps = {
    href: resourceHref,
    target: "_blank",
    rel: "noreferrer",
    sx: { textDecoration: "none", "&:hover": { color: "#153862" } },
  };

  return (
    <Link
      {...commonProps}
      onClick={handleNavClick}
      data-test-id={`${item.value}-nav-item-link-element`}
    >
      <ListItem
        id={`${item.value}-nav-item`}
        data-test-id={`${item.value}-nav-item`}
        title={translatedItemTitle}
        aria-label={item.text}
        value={item.value}
        disablePadding
        sx={{ display: "block" }}
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: isMobile ? "initial" : "center",
            px: isMobile ? 1.2 : 2.5,
            mb: isMobile ? "0px" : "10px",
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: isMobile ? (drawerOpen ? 3 : "auto") : open ? 3 : "auto",
              justifyContent: "center",
            }}
          >
            <ActionableIndicator
              padRight={-6}
              invisible={!(isActionable(item.text) && !(isMobile ? (!!drawerOpen) : !!open))}>
              {getIcon(item.value)}
            </ActionableIndicator>
          </ListItemIcon>
          <ListItemText
            primary={
              <ActionableIndicator invisible={!isActionable(item.text)}>
                <Box
                  sx={{ display: "flex", gap: "4px", alignItems: "flex-start" }}>
                  <Stack flexDirection="row" gap={.5} ref={elementRef}>                  
                    <T>{item.text}</T>
                    {item.value === "new-library" && (
                      <Stack
                        justifyContent="center"
                        sx={{
                          fontSize: "8px",
                          padding: "2px 6px",
                          color: theme.subscription.subscribed.color,
                          backgroundColor: theme.subscription.subscribed.backgroundColor,
                          borderRadius: "3px",
                        }}
                      >
                        <T>beta</T>
                      </Stack>
                    )}                  
                  </Stack>
                  <Box>
                    <img
                      src="icons/external.svg"
                      alt="external icon"
                      style={{ transform: languageDir === "rtl" ? "rotateY(180deg)" : "none" }}
                    />
                  </Box>
                </Box>
              </ActionableIndicator>
            }
            primaryTypographyProps={{
              fontFamily: "Inter",
              fontWeight: "600",
              fontSize: "12px",
            }}
            sx={{ opacity: isMobile ? (drawerOpen ? 1 : 0) : open ? 1 : 0 }}
          />
        </ListItemButton>
      </ListItem>
    </Link>
  );
};

const getIcon = (val) => {
  switch (val) {
    case "library":
      return <Box sx={{ display: "inline" }}><img src="icons/library-icon.svg" alt="library icon" /></Box>;
    case "external-help":
      return <Box sx={{ display: "inline" }}><img src="icons/external-help.svg" alt="help icon" /></Box>;
    case "new-library":
      return (
        <Box sx={{ display: "inline"}}>
          <Badge
            variant="dot"
            overlap="circular"
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            sx={{
              display: "inline",
              "& .MuiBadge-badge": {
                backgroundColor: "#BA71AE",
                position: "absolute",
                top: "4px",
                left: "4px",
                minWidth: "6px",
                width: "6px",
                height: "6px",
              },
            }}
          >
            <img src="icons/new-library.svg" alt="new library icon" />
          </Badge>
        </Box>
      );
    case "shop-feel-great":
      return <Box sx={{ display: "inline" }}><img src="icons/shop-feel-great.svg" alt="shop feel great icon" /></Box>;
    case "shop-unicity":
      return <Box sx={{ display: "inline" }}><img src="icons/shop-unicity.svg" alt="shop unicity icon" /></Box>;
    default:
      throw new Error("Icon not found.");
  }
};

export { ResourceNavItem };
