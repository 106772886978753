import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Stack, Box, Link } from "@mui/material";
import { useMixPanel } from "../../hooks/useMixPanel";

import { OfficeAvatar } from "../../components/OfficeAvatar";
import { CustomerTypeChip } from "../../components/CustomerTypeChip";

import { decodeString } from "../../common/format-functions";

import { parseProfilePictureUrl, getTruncatedName } from "../../common/helper-functions"

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// name cell for MembersDataGrid with nav link to members sidebar
const MemberInfo = ({ params: member, dataGridParams }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { logEvent } = useMixPanel();

  const theme = useTheme();
  const viewportIsLarge = useMediaQuery(theme.breakpoints.up("lg"));

  const cellWidth = dataGridParams.colDef.computedWidth;
  const [truncateName, setTruncateName] = useState(false);
  const firstName = member.firstName;
  const lastName = member.lastName;

  const truncatedName = getTruncatedName(firstName, lastName, truncateName);

  // conditionally truncate name based on cell width
  useEffect(() => {
    cellWidth < 190
      ? setTruncateName(true)
      : setTruncateName(false);
    // eslint-disable-next-line
  }, [cellWidth]);

  const profilePictureUrl = parseProfilePictureUrl(member.profilePicture);

  // boolean to show/hide customer type chip based on whether values should be displayed
  // https://app.clickup.com/t/863hbdtmt
  const showCustomerTypeChip = ["Customer", "WholesaleCustomer", "PreferredCustomer"].includes(member.customerType);

  // set MemberContext and open menu with available params
  const handleNameClick = () => {
    logEvent("open_profile", {
      location: "members_page",
      user_type: "member"
    })
    navigate(`${location.pathname}?drawer=member&id=${member.unicityId}&drawerContent=profile`, { state: location });
  };

  return (
    <Box className="flex-row" gap={1}>
      <Box>
        <OfficeAvatar
          id="user-avatar"
          imageUrl={profilePictureUrl}
          firstInitial={member.name[0]?.toUpperCase()}
        />
      </Box>
      <Box className="flex-column" style={{ alignItems: "flex-start" }}>
        <Stack
          flexDirection="row"
          gap={.5}
          alignItems="baseline"
          sx={{
            width: window.innerWidth / 10 > 100 ? window.innerWidth / 10 : 100
          }}>
          <Link
            sx={{ fontSize: "12px" }}
            component="button"
            underline="hover"
            href="#"
            onClick={handleNameClick}
          >
            <Stack
              className="truncate"
              flexDirection="row"
              alignItems="center"
              gap={.5}
            >
              <span style={{ fontWeight: 400 }}>{decodeString(truncatedName.firstNameTruncated)}</span>{" "}
              <span style={{ fontWeight: 600 }}>{decodeString(truncatedName.lastNameTruncated)}</span>
            </Stack>
          </Link>
          {
            showCustomerTypeChip && viewportIsLarge && <CustomerTypeChip customerType={member.customerType} />
          }
        </Stack>
        <Box style={{ fontSize: "10px", fontWeight: 400, opacity: "60%" }}>
          {member.unicityId}
        </Box>
        {
          showCustomerTypeChip && !viewportIsLarge && <CustomerTypeChip customerType={member.customerType} />
        }
      </Box>
    </Box>
  );
};

export { MemberInfo };
