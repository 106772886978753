import React from "react";

import { Stack } from "@mui/material";
import { SuspendedChip } from "./SuspendedChip";
import { InactiveChip } from "./InactiveChip";
import { MemberChip } from "./MemberChip";
import { isDate60DaysInactive } from "../../../common/helper-functions";

// TODO - Make use of the global badge component instead of the individual badges
// THIS IS FOR WEB VIEW ONLY
const ChipContainer = ({
  viewportIsLarge,
  customerType,
  member,
  leg1UnicityId,
  leg2UnicityId
}) => {

  return (
    <Stack
      direction={viewportIsLarge ? "row" : "column"}
      gap={.5}
    >
      {
        member?.unicityId?.trim() === leg1UnicityId?.trim() && (
          // <Chip
          //   translationKey="leg1"
          //   customTextStyles={{
          //     color: "#43C06F"
          //   }}
          //   customChipStyles={{
          //     padding: "1px 6px",
          //     background: "rgba(67, 192, 111, 0.16)"
          //   }}
          // />
          // Comment out due to incomplete back end functionality. https://app.clickup.com/t/86du321dm
          <></>
        )
      }
      {
        //   member?.unicityId?.trim() === leg2UnicityId?.trim() && (
        //   <Chip
        //     translationKey="leg2"
        //     customTextStyles={{
        //       color: "#009ECF"
        //     }}
        //     customChipStyles={{
        //       padding: "1px 6px",
        //       background: "rgba(0, 158, 207, 0.16)"
        //     }}
        //   />
        // )
        // Comment out due to incomplete back end functionality. https://app.clickup.com/t/86du321dm
        <></>
      }
      {
        customerType === "member" && (
          <MemberChip />
        )
      }
      {
        member.customerStatus === "Suspended" && (
          <SuspendedChip />
        )
      }
      {
        // if a partner, check inactive status by is100PvActive, if a member, check by lastOrderDate
        ((customerType === "partner" && !member.is100PvActive) ||
          (customerType === "member" && isDate60DaysInactive(member.lastOrderDate))) && (
          <InactiveChip />
        )
      }
    </Stack>
  )
}

const TopChips = ({ member, leg1UnicityId, leg2UnicityId }) => {
  return (
    <Stack
      direction="row"
      gap={.5}
      sx={{ marginLeft: "5px" }}
    >
      {
        member?.unicityId?.trim() === leg1UnicityId?.trim() && (
          // <Chip
          //   translationKey="leg1"
          //   customTextStyles={{
          //     color: "#43C06F"
          //   }}
          //   customChipStyles={{
          //     padding: "1px 6px",
          //     background: "rgba(67, 192, 111, 0.16)"
          //   }}
          // />
          // Comment out due to incomplete back end functionality. https://app.clickup.com/t/86du321dm
          <></>
        )
      }
      {
        member?.unicityId?.trim() === leg2UnicityId?.trim() && (
          // <Chip
          //   translationKey="leg2"
          //   customTextStyles={{
          //     color: "#009ECF"
          //   }}
          //   customChipStyles={{
          //     padding: "1px 6px",
          //     background: "rgba(0, 158, 207, 0.16)"
          //   }}
          // />
          // Comment out due to incomplete back end functionality. https://app.clickup.com/t/86du321dm
          <></>
        )
      }
    </Stack>
  )
}

//  CHIPS NEXT TO THE UNICITY ID
const BottomChips = ({ member, customerType }) => {
  return (
    <Stack
      direction="row"
      gap={.5}
      sx={{ marginLeft: "5px" }}
    >
      {
        customerType === "member" && (
          <MemberChip />
        )
      }
      {
        member.customerStatus === "Suspended" && (
          <SuspendedChip />
        )
      }
      {
        // if a partner, check inactive status by is100PvActive, if a member, check by lastOrderDate
        ((customerType === "partner" && !member.is100PvActive) ||
          (customerType === "member" && isDate60DaysInactive(member.lastOrderDate))) && (
          <InactiveChip />
        )
      }
    </Stack>
  )
}

export { ChipContainer, BottomChips, TopChips }