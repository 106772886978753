import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

//Element for use in creating authorized routes (see OfficeRouter.js)
function RequireAuth({ children }) {
  const { userInfo } = useAuth();
  const location = useLocation();

  if (userInfo.loggedIn && userInfo.type === 'Associate')
    return children;

  return <Navigate to="/login" replace state={{ path: location.pathname }} />
}

export { RequireAuth };
