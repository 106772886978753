import React from "react";
import { T, useTranslateKey } from "../../hooks/useTranslate";

import { Box, MenuItem, Typography, Link } from "@mui/material";

const SettingsMenuItem = ({
  id,
  icon,
  text1,
  text2,
  testId,
  useHref,
  urlKey,
  clickHandler,
  customHref,
  rightIcon
}) => {
  const href = useTranslateKey(urlKey);

  const commonProps = {
    target: "_blank",
    rel: "noreferrer",
    sx: { textDecoration: "none", "&:hover": { color: "#153862" } },
    ...(useHref ? { href } : { href: customHref }),
  };

  return (
    <Link
      {...commonProps}
      onClick={clickHandler && clickHandler}>
      <MenuItem
        id={testId}
        data-test-id={testId}
        sx={{
          color: id === "sign-out" && "#DF4646"
        }}
      >
        <img src={`icons/${icon}.svg`} alt={`${icon} icon`} />
        <Box ml={1.5} mr={id === 'language' ? 0 : 1.5}>
          <span style={{ marginLeft: id === 'order-history' || id === 'ar-order' ? '4px' : '0' }}><T>{text1}</T></span>
        </Box>
        {
          id === "language" &&
          <>
            {`: ${text2}`}
            <Typography variant="h6" ml={1.5}>
              <T isUpperCase={true}>change</T>
            </Typography>
          </>
        }
        {rightIcon && <img src={`icons/${rightIcon}.svg`} alt={`${rightIcon} icon`} />}
      </MenuItem>
    </Link >
  )
}

export { SettingsMenuItem }