import React, { useEffect, useState, createContext } from "react";

import { useApi } from "./useApi";
import { useAuth } from "./useAuth"
import { getMonthRange, getQualificationPeriodMonth } from "../common/date-functions";
import { decodeString, formatName } from "../common/format-functions"

const CenturionContext = createContext([{}, () => { }]);

const CenturionContextProvider = (props) => {
  const [contextMonthRange, setContextMonthRange] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [sales, setSales] = useState(0);
  const [currentMembers, setCurrentMembers] = useState([]);
  const [isError, setError] = useState(false);
  const [dateCalculated, setDateCalculated] = useState("");

  const { response, status, sendRequest } = useApi();
  const { userInfo } = useAuth();

  const resetContext = () => {
    setSales(0);
    setCurrentMembers([]);
    setDateCalculated("");
  }

  useEffect(() => {
    if (status === "wait") {
      setDateCalculated("");
    }
  }, [status]);

  useEffect(() => {
    if (!userInfo.loggedIn) {
      resetContext();
    }
  }, [userInfo])

  useEffect(() => {
    setContextMonthRange(getMonthRange(6));
    setSelectedMonth(getQualificationPeriodMonth(6));
  }, [])

  // Sets and refreshes Centurion context based on selectedMonth
  // An interval will refresh the page every two minutes if left open
  useEffect(() => {
    if (userInfo.token) {
      const updateContext = () => {
        sendRequest({
          method: "get",
          endpoint: `customers/me/centurionHistory?period=${selectedMonth}`,
        });
      };

      // Initial data fetch
      if (selectedMonth) updateContext();

      // Set interval to refresh context every two minutes
      const updateTimer = setInterval(updateContext, 120000);

      // Clean up the interval when the component unmounts
      return () => {
        clearInterval(updateTimer);
      };
    }
    // eslint-disable-next-line
  }, [selectedMonth, userInfo.token]);

  useEffect(() => {
    if (status === "error") {
      setError(true)
    }

    if (status === "complete" && response.data?.centurionHistory?.items[0].value[0].metrics[0].dateCalculated) {
      setDateCalculated(response.data?.centurionHistory?.items[0].value[0].metrics[0].dateCalculated)
    }
    if (!response.data?.centurionHistory?.items[0].value[0].metrics[0].value) {
      setSales(0);
    }
    if (status === "complete" && response.data?.centurionHistory?.items[0].value[0].metrics[0].value) {
      setError(false)
      const centurionHistory = response.data?.centurionHistory?.items[0];
      const currentCentCount = centurionHistory.value[0].metrics[0].value;

      const filteredMembers = response.data.centurionHistory?.items[0].metricsDetails?.count?.customers
        .filter(c =>
          (c.customer.centurionCount.value > 0)
        )
        .sort((member1, member2) => {
          return (
            member2.customer.centurionCount.value -
            member1.customer.centurionCount.value
          );
        })
        .map((member, idx) => {
          return {
            id: idx,
            name: formatName(decodeString(member.customer.humanName.fullName)),
            count: member.customer.centurionCount.value,
            unicityId: member.customer.id.unicity,
            hasAutoOrder: member.customer.hasAutoOrder,
            lastFeelGreatOrderDate: member.customer.lastFeelGreatOrderDate,
            hasFeelGreatAutoOrder: member.customer.hasFeelGreatAutoOrder,
            nextRunDate: member.customer.nextRunDate
              ? member.customer.nextRunDate
              : undefined,
          };
        });

      // if there are issues getting members from the API call, fill page with blank array, and render no sales screen
      filteredMembers === undefined ? setCurrentMembers([]) : setCurrentMembers(filteredMembers)
      setSales(currentCentCount);
    }
  }, [status, response, selectedMonth])

  return (
    <CenturionContext.Provider
      value={{
        sales,
        setSales,
        selectedMonth,
        contextMonthRange,
        setSelectedMonth,
        status,
        currentMembers,
        setCurrentMembers,
        isError,
        dateCalculated
      }}
    >
      {props.children}
    </CenturionContext.Provider>
  );
};

export { CenturionContext, CenturionContextProvider };
